export const navMenu = [
    'Home' ,
    'Sign up',
    'Slot',
    'Table Game',
    'Live Casino',
    'Sports',
    'Sabong',
    'Banking',
    'Promotion',
    'Download App'
]